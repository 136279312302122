import './styles/main.scss'
import gsap from 'gsap';

let loadingScreen = document.querySelector('#loadingPage');

function hideElement(element) {
    if (loadingScreen && loadingScreen.setAttribute) {
        loadingScreen.setAttribute("style", "display: none;");
    }
}

window.addEventListener('load', function(){
    gsap.to(".staggerElement", { y: '-200px', opacity: "0", duration: 0.8, stagger: 0.1, ease: 'Power1.easeIn'});
    gsap.to(loadingScreen, {opacity: 0, duration: 0.8, delay: 0.8, ease: "Power2.easeIn", onComplete: hideElement});
});

